body {
  margin: 0;
  font-family: 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: 'scroll';
  overflow-x: hidden;
  background-color: '#ffffff';
}
a {
  text-decoration: none;
  color: inherit;
}
