.underline {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

.pos-rel {
  position: relative;
}

.sb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-1 {
  flex: 1;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.focus-visible {
  outline-color: rgba(129, 202, 231, 0.3);
}

.header {
  background: rgb(237, 237, 237);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 10px;
  /* Fix for height bug with chat sidebar */
  min-height: 60px;
}

.avatar {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.emoji {
  /* background: url(../../assets/images/emoji-sprite.png) transparent; */
  width: 40px;
  height: 40px;
  background-size: 400px;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  background-size: 500px;
}

/* Begin search input  */

.search-wrapper {
  padding: 7px 10px;
  height: 40px;
  /* background: #f6f6f6;
   */
  background: #0da9e5;
  position: relative;
}

.search-wrapper:focus-within {
  background: white;
}

.search {
  background: white;
  color: rgb(74, 74, 74);
  padding-left: 60px;
  border-radius: 18px;
  width: 100%;
  height: 100%;
}

.search::placeholder {
  color: rgb(153, 153, 153);
}

.search-icons {
  color: #919191;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  overflow: hidden;
}

.search-icon,
.search__back-btn {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.8s ease;
}

.search-icon {
  opacity: 1;
  transition-delay: 0.3s;
}

.search__back-btn {
  opacity: 0;
  transition-delay: 0.3s;
  color: rgb(51, 183, 246);
}

.search-wrapper:focus-within .search-icon {
  opacity: 0;
  transition-delay: 0s;
}

.search-wrapper:focus-within .search__back-btn {
  transform: rotate(360deg);
  opacity: 1;
  transition-delay: 0s;
}

/* End search input  */
.sidebar {
  min-width: 300px;
  flex: 40%;
  border-right: 1px solid #dadada;
  display: flex;
  flex-direction: column;
}

/*  Sidebar Header */

.sidebar__avatar-wrapper {
  width: 40px;
  height: 40px;
}

.sidebar__actions {
  margin-right: 20px;
}

.sidebar__actions > * {
  display: inline-block;
  margin-left: 25px;
  cursor: pointer;
}

.sidebar__action-icon {
  color: rgb(145, 145, 145);
}

/* End Sidebar Header */

/*  Sidebar Alert */

.sidebar__alert {
  min-height: 85px;
  padding: 20px;
  display: flex;
  align-items: center;
}

.sidebar__alert--warning {
  background: #fed859;
}

.sidebar__alert--info {
  background: #9de1fe;
}

.sidebar__alert--danger {
  background: #f3645b;
}

.sidebar__alert-icon-wrapper {
  margin-right: 10px;
}

.sidebar__alert-icon {
  color: white;
}

.sidebar__alert-texts {
  flex: 1;
}

.sidebar__alert-text:first-of-type {
  font-size: 1rem;
  margin-bottom: 5px;
  color: #343738;
}

.sidebar__alert-text:last-of-type {
  font-size: 0.85rem;
  color: #414a4e;
  line-height: 17px;
}

.sidebar__alert--danger .sidebar__alert-text:first-of-type,
.sidebar__alert--danger .sidebar__alert-text:last-of-type {
  color: white;
}

/* End Sidebar Alert */

/*  Sidebar Search */

.sidebar__search-wrapper {
  padding: 7px 10px;
  height: 50px;
  background: #f6f6f6;
  position: relative;
}

/* End Sidebar Search */

/*  Sidebar Contact List */

.sidebar__contacts {
  flex: 1;
  /* overflow-y: scroll; */
  background: #f5f5f5;
  border-top: 1px solid #dadada;
}

.sidebar-contact {
  height: 72px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  cursor: pointer;
}

.sidebar-contact:hover {
  background-color: #ebebeb;
}

.sidebar-contact__avatar-wrapper {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.sidebar-contact__content {
  overflow: hidden;
  flex: 1;
}

.sidebar-contact__top-content,
.sidebar-contact__bottom-content,
.sidebar-contact__message-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar-contact__name,
.sidebar-contact__message {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sidebar-contact__top-content {
  margin-bottom: 2px;
}

.sidebar-contact__name {
  color: #000000;
  font-size: 1rem;
  font-weight: 500;
}

.sidebar-contact__time {
  font-size: 0.7rem;
  color: rgba(0, 0, 0, 0.45);
}

.sidebar-contact__message-wrapper {
  color: #00000099;
  font-size: 0.85rem;
  margin-right: 3px;
  overflow: hidden;
}

.sidebar-contact__message-icon {
  color: #b3b3b3;
  margin-right: 3px;
}

.sidebar-contact__message-icon--blue {
  color: #0da9e5;
}

.sidebar-contact__message--unread {
  color: #000000;
  font-weight: 500;
}

.sidebar-contact__icons,
.sidebar-contact:not(:focus) .sidebar-contact__icons {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(24px);
  transition: transform 0.5s ease;
}

.sidebar-contact:hover .sidebar-contact__icons {
  transform: translateX(0);
}

.sidebar-contact__icons > * {
  margin-left: 8px;
  color: #b3b3b3;
}

.sidebar-contact__unread {
  display: inline-block;
  color: white;
  background-color: #0092bc;
  border-radius: 18px;
  min-width: 18px;
  height: 18px;
  padding: 0 3px;
  line-height: 18px;
  vertical-align: middle;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 500;
}

/*  End Sidebar Contact List */

@media screen and (min-width: 1000px) and (max-width: 1300px) {
  .sidebar {
    flex: 35%;
  }
  .sidebar ~ div {
    flex: 65%;
  }
}

@media screen and (min-width: 1301px) {
  .sidebar {
    flex: 30%;
  }
  .sidebar ~ div {
    flex: 70%;
  }
}
.home {
  /* background: #f8f9fa;
   */
  background: transparent;
  padding: 20px;
  height: 100%;
  flex: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 6px solid #0092bc;
}

.home__img-wrapper {
  width: 250px;
  height: 250px;
  margin-bottom: 20px;
}

.home__img {
  width: 100%;
  height: 100%;
  /* border-radius: 50%; */
}

.home__title {
  color: #525252;
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 10px;
}

.home__text {
  color: rgba(0, 0, 0, 0.45);
  font-size: 0.85rem;
  font-weight: 500;
  max-width: 500px;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.home__text:first-of-type {
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(74, 74, 74, 0.08);
}

.home__text:last-of-type {
  padding-top: 30px;
}

.home__icon {
  display: inline-block;
  margin-right: 5px;
}

.home__link {
  color: #0092bc;
}
.chat {
  display: flex;
  position: relative;
}

.chat__body {
  min-width: 300px;
  flex: 40%;
  /* border-right: 1px solid #dadada; */
  /* border: 1px solid transparent; */
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.chat__bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0.05;
  z-index: 1;
  /* background: url(../../../assets/images/bg-chat-light.png) #e4dcd4; */
}

.chat__header,
.chat__footer,
.chat__date-wrapper,
.chat__msg-group,
.chat__encryption-msg {
  z-index: 10;
}

.chat__header {
  /* Needed for the options btn to stay on top */
  z-index: 20;
}

/* Chat Header Component  */

.chat__avatar-wrapper {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
}

.chat__contact-wrapper {
  flex: 1;
  cursor: pointer;
}

.chat__contact-name,
.chat__contact-desc {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.chat__contact-name {
  color: #000000;
  font-size: 1rem;
  margin-bottom: 2px;
  font-weight: bold;
  text-transform: capitalize;
}

.chat__contact-desc {
  color: #00000099;
  font-size: 0.75rem;
}

.chat__actions {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.chat__action {
  margin-left: 25px;
  cursor: pointer;
}

.chat__action:not(.options-btn) {
  display: inline-block;
}

.chat__action-icon {
  color: rgb(145, 145, 145);
}

.chat__action-icon--search {
  width: 30px;
  height: 30px;
}

/* End Chat Header Component  */

/* Chat Content */
html {
  height: 100%;
}

.chat__content {
  flex: 1;
  z-index: 1000;
  position: relative;
  display: block;
  /* background: #e4dcd4; */
  background: white;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 20px 5% 2pc;
}

.chat__date-wrapper {
  text-align: center;
  margin: 10px 0 14px;
  position: relative;
}

.chat__date {
  background: #e1f2fa;
  display: inline-block;
  color: #000000;
  font-size: 0.75rem;
  padding: 7px 10px;
  border-radius: 5px;
}

.chat__encryption-msg {
  background: #fdf4c5;
  color: #000000;
  font-size: 0.77rem;
  text-align: center;
  padding: 5px 10px;
  position: relative;
  margin-bottom: 8px;
  border-radius: 5px;
  line-height: 20px;
}

.chat__encryption-icon {
  color: #8c866c;
  margin-right: 5px;
  margin-bottom: -1px;
}

.chat__msg-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  position: relative;
}

.chat__msg {
  padding: 6px 7px 8px 9px;
  margin-bottom: 12px;
  font-size: 0.85rem;
  color: #000000;
  width: fit-content;
  max-width: 95%;
  line-height: 20px;
  border-radius: 5px;
  position: relative;
  white-space: pre-line;
  display: flex;
}

.chat__msg.chat__img-wrapper {
  padding: 4px;
  width: 95%;
}

.chat__msg--sent {
  background: #dbf8c6;
  align-self: flex-end;
}

.chat__msg--rxd {
  background: white;
  align-self: flex-start;
}

.chat__msg-group > *:nth-child(1):not(.chat__msg--sent)::before,
.chat__msg--sent + .chat__msg--rxd::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  left: -8px;
  border-top: 6px solid white;
  border-right: 6px solid white;
  border-bottom: 6px solid transparent;
  border-left: 6px solid transparent;
}

.chat__msg-group > *:nth-child(1):not(.chat__msg--rxd)::before,
.chat__msg--rxd + .chat__msg--sent::before {
  right: -8px;
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  border-top: 6px solid #dbf8c6;
  border-right: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #dbf8c6;
}

.chat__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.chat__msg-filler {
  width: 65px;
  display: inline-block;
  height: 3px;
  background: transparent;
}

.chat__msg-footer {
  position: absolute;
  display: flex;
  align-items: center;
  right: 7px;
  bottom: 3px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 0.7rem;
  font-weight: 500;
}

.chat__msg-status-icon {
  color: #b3b3b3;
  margin-left: 3px;
}

.chat__msg-status-icon--blue {
  color: #0da9e5;
}

.chat__img-wrapper .chat__msg-footer,
.chat__img-wrapper .chat__msg-options-icon,
.chat__img-wrapper .chat__msg-status-icon {
  color: white;
}

.chat__msg-options {
  opacity: 0;
  position: absolute;
  right: 5px;
  top: 3px;
  pointer-events: none;
  transition: all 0.2s;
}

.chat__msg--rxd .chat__msg-options {
  background: white;
}

.chat__msg--sent .chat__msg-options {
  background: #0092bc;
}

.chat__img-wrapper .chat__msg-options {
  background: transparent;
}

.chat__msg:hover .chat__msg-options {
  opacity: 1;
  pointer-events: unset;
}

.chat__msg-options-icon {
  color: rgb(145, 145, 145);
  width: 20px;
  height: 20px;
}

/* End Chat Content */

.chat__footer {
  background: rgb(240, 240, 240);
  position: relative;
}

.chat__scroll-btn {
  position: absolute;
  right: 15px;
  bottom: 80px;
  width: 42px;
  height: 42px;
  z-index: -1;
  border-radius: 50%;
  color: rgb(145, 145, 145);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

/* Emoji Tray */

.emojis__wrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 0;
  min-height: 0;
  transition: all 0.4s ease;
  background: inherit;
}

.emojis__wrapper--active {
  height: 40vh;
  min-height: 350px;
  transition: all 0.4s ease;
}

.emojis__tabs {
  display: flex;
  align-items: center;
  height: 50px;
}

.emojis__tab {
  flex: 1;
  padding: 10px 5px 10px;
  text-align: center;
  position: relative;
}

.emojis__tab--active::after {
  content: '';
  position: absolute;
  height: 4px;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #0092bc;
}

.emojis__tab-icon {
  color: rgba(0, 0, 0, 0.32);
}

.emojis__tab--active .emojis__tab-icon {
  color: rgba(0, 0, 0, 0.6);
}

.emojis__content {
  overflow-y: scroll;
  padding: 5px 20px;
  flex: 1;
}

.emojis__search {
  height: 40px;
  background: #e6e6e6;
  width: 100%;
  border-radius: 5px;
  padding: 5px 10px;
  color: rgb(74, 74, 74);
  font-size: 0.9rem;
}

.emojis__search::placeholder {
  color: #989898;
}

.emojis__label {
  margin-top: 15px;
  margin-bottom: 5px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.45);
  font-size: 0.85rem;
}

.emojis__grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

.emojis__emoji {
  margin-right: 3px;
  margin-top: 3px;
  cursor: pointer;
}

/* End Emoji Tray */

/* Chat Footer Toolbar */

.chat__input-wrapper {
  padding: 10px;
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
}

.chat__input-icon {
  color: #919191;
  margin-left: 8px;
  margin-right: 8px;
  width: 28px;
  height: 28px;
  padding: 3px;
  border-radius: 50%;
}

.chat__input-icon--highlight {
  color: #0092bc;
}

.chat__attach {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 50px;
}

.chat__attach-btn {
  transform: scale(0);
  opacity: 0;
  transition: all 0.5s ease;
}

.chat__attach-btn:nth-of-type(1) {
  transition-delay: 0.5s;
}

.chat__attach-btn:nth-of-type(2) {
  transition-delay: 0.4s;
}

.chat__attach-btn:nth-of-type(3) {
  transition-delay: 0.3s;
}

.chat__attach-btn:nth-of-type(4) {
  transition-delay: 0.2s;
}

.chat__attach-btn:nth-of-type(5) {
  transition-delay: 0.1s;
}

.chat__attach--active .chat__attach-btn {
  transform: scale(1);
  opacity: 1;
}

.chat__attach-btn {
  margin-bottom: 10px;
}

.chat__input-icon--pressed {
  background: rgba(0, 0, 0, 0.1);
}

.chat__input {
  background: white;
  color: rgb(74, 74, 74);
  padding: 20px 10px;
  border-radius: 22px;
  flex: 1;
  height: 100%;
}

.chat__input::placeholder {
  color: rgb(153, 153, 153);
  font-size: 0.9rem;
}

/* End Chat Footer Toolbar */

/* Chat Sidebar */

.chat-sidebar {
  width: 0;
  min-width: 0;
  display: flex;
  flex-direction: column;
  transition: all 0.1s ease;
  overflow-x: hidden;
  overflow-y: auto;
}

.chat-sidebar--active {
  flex: 30%;
}

.chat-sidebar__header-icon {
  margin-right: 20px;
  color: rgb(145, 145, 145);
}

.chat-sidebar__heading {
  flex: 1;
  color: #000000;
  font-size: 1rem;
  margin-bottom: 2px;
}

.chat-sidebar__content {
  flex: 1;
}

.chat-sidebar__search-results {
  background: white;
  height: 100%;
  padding-top: 5pc;
  color: #00000099;
  text-align: center;
  font-size: 0.85rem;
}

.profile {
  background: rgb(237, 237, 237);
  padding-bottom: 2pc;
}

.profile__section {
  background: white;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
  padding: 10px 20px;
}

.profile__section--personal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
}

.profile__avatar-wrapper {
  width: 200px;
  width: 200px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile__name {
  flex: 1;
  color: #000000;
  font-size: 1.2rem;
  align-self: flex-start;
}

.profile__heading-wrapper {
  margin-top: 5px;
  margin-bottom: 10px;
}

.profile__heading {
  color: #0092bc;
  font-size: 0.85rem;
  flex: 1;
}

.profile__heading-icon {
  color: rgb(145, 145, 145);
}

.profile__media-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile__media {
  width: 32%;
}

.profile__action,
.profile__about-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  margin-bottom: 5px;
  cursor: pointer;
}

.profile__action:not(:last-of-type),
.profile__about-item:not(:last-of-type),
.profile__group:not(:last-of-type) {
  border-bottom: 1px solid #ebebeb;
}

.profile__action-left {
  flex: 1;
}

.profile__action-text {
  display: block;
}

.profile__action-text--top,
.profile__about-item {
  font-weight: 500;
  margin-bottom: 5px;
}

.profile__action-text--bottom {
  font-size: 0.85rem;
  color: rgba(0, 0, 0, 0.45);
}

.profile__section--groups {
  padding-left: 0;
  padding-right: 0;
}

.profile__group,
.profile__group-heading {
  padding-left: 20px;
  padding-right: 20px;
}

.profile__group {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.profile__group:hover {
  background-color: #ebebeb;
}

.profile__group-content {
  flex: 1;
  overflow: hidden;
}

.profile__group-avatar-wrapper {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.profile__group-text {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.profile__group-text--top {
  color: #000000;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 5px;
}

.profile__group-text--bottom {
  color: #00000099;
  font-size: 0.85rem;
  overflow: hidden;
}

.profile__section--danger {
  color: rgb(223, 51, 51);
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.profile__danger-icon {
  margin-right: 20px;
}

.profile__danger-text {
  flex: 1;
}

/* End  Chat Sidebar */

@media screen and (min-width: 1301px) {
  .chat__msg {
    max-width: 65%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1300px) {
  .chat__msg {
    max-width: 75%;
  }
}

@media screen and (min-width: 900px) and (max-width: 1000px) {
  .chat__msg {
    max-width: 85%;
  }
}

@media screen and (max-width: 1000px) {
  .chat-sidebar {
    transition: transform 0.1s ease;
    transform: translateX(120vw);
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  .chat-sidebar--active {
    transform: translateX(0);
    transition: transform 0.1s ease;
  }
}

@media screen and (min-width: 750px) {
  .chat__msg.chat__img-wrapper {
    width: 40%;
    min-width: 300px;
    max-width: 400px;
  }
}
.checkbox__input {
  position: fixed;
  opacity: 0;
  width: 0;
}

.checkbox__label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgb(145, 145, 145);
  width: 20px;
  height: 20px;
  border-radius: 2px;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.checkbox__input:checked ~ .checkbox__label {
  background-color: #0092bc;
  border: 2px solid #0092bc;
}

.checkbox__icon {
  width: 92%;
  height: 100%;
  color: transparent;
}

.checkbox__input:checked ~ .checkbox__label .checkbox__icon {
  color: white;
}
.app {
  width: 100%;
  min-height: 86vh;
  background: #f2f2f2;
  position: relative;
}

.app::before {
  width: 100%;
  height: 120px;
  top: 0;
  left: 0;
  background: transparent;
  position: absolute;
  content: '';
  border-radius: 8px;
  z-index: 1;
}

.app__mobile-message {
  display: none;
}

.app-content {
  width: 100%;
  height: 86vh;
  max-width: 1450px;
  margin: 0 auto;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 8px;
  z-index: 100;
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
}

@media screen and (max-width: 500px) {
  .app__mobile-message {
    padding-top: 200px;
    text-align: center;
    font-size: 1.2rem;
    display: block;
  }
  .app-content {
    display: none;
  }
}

@media screen and (min-width: 1450px) {
  .app {
    padding: 20px;
  }
  .app-content {
    height: calc(100vh - 40px);
  }
}
.sidebar {
  min-width: 300px;
  flex: 40%;
  border-right: 1px solid #dadada;
  display: flex;
  flex-direction: column;
}

/*  Sidebar Header */

.sidebar__avatar-wrapper {
  width: 40px;
  height: 40px;
}

.sidebar__actions {
  margin-right: 20px;
}

.sidebar__actions > * {
  display: inline-block;
  margin-left: 25px;
  cursor: pointer;
}

.sidebar__action-icon {
  color: rgb(145, 145, 145);
}

/* End Sidebar Header */

/*  Sidebar Alert */

.sidebar__alert {
  min-height: 85px;
  padding: 20px;
  display: flex;
  align-items: center;
}

.sidebar__alert--warning {
  background: #fed859;
}

.sidebar__alert--info {
  background: #9de1fe;
}

.sidebar__alert--danger {
  background: #f3645b;
}

.sidebar__alert-icon-wrapper {
  margin-right: 10px;
}

.sidebar__alert-icon {
  color: white;
}

.sidebar__alert-texts {
  flex: 1;
}

.sidebar__alert-text:first-of-type {
  font-size: 1rem;
  margin-bottom: 5px;
  color: #343738;
}

.sidebar__alert-text:last-of-type {
  font-size: 0.85rem;
  color: #414a4e;
  line-height: 17px;
}

.sidebar__alert--danger .sidebar__alert-text:first-of-type,
.sidebar__alert--danger .sidebar__alert-text:last-of-type {
  color: white;
}

/* End Sidebar Alert */

/*  Sidebar Search */

.sidebar__search-wrapper {
  padding: 7px 10px;
  height: 50px;
  background: #f6f6f6;
  position: relative;
}

/* End Sidebar Search */

/*  Sidebar Contact List */

.sidebar__contacts {
  /* flex: 1; */
  /* overflow-y: scroll; */
  background: #f5f5f5;
  border-top: 1px solid #dadada;
}

.sidebar-contact {
  height: 72px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  cursor: pointer;
}

.sidebar-contact:hover {
  background-color: #ebebeb;
}

.sidebar-contact__avatar-wrapper {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.sidebar-contact__content {
  overflow: hidden;
  flex: 1;
}

.sidebar-contact__top-content,
.sidebar-contact__bottom-content,
.sidebar-contact__message-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar-contact__name,
.sidebar-contact__message {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sidebar-contact__top-content {
  margin-bottom: 2px;
}

.sidebar-contact__name {
  color: #000000;
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 500;
}

.sidebar-contact__time {
  font-size: 0.7rem;
  color: rgba(0, 0, 0, 0.45);
}

.sidebar-contact__message-wrapper {
  color: #00000099;
  font-size: 0.85rem;
  margin-right: 3px;
  overflow: hidden;
}

.sidebar-contact__message-icon {
  color: #b3b3b3;
  margin-right: 3px;
}

.sidebar-contact__message-icon--blue {
  color: #0da9e5;
}

.sidebar-contact__message--unread {
  color: #000000;
  font-weight: 500;
}

.sidebar-contact__icons,
.sidebar-contact:not(:focus) .sidebar-contact__icons {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(24px);
  transition: transform 0.5s ease;
}

.sidebar-contact:hover .sidebar-contact__icons {
  transform: translateX(0);
}

.sidebar-contact__icons > * {
  margin-left: 8px;
  color: #b3b3b3;
}

.sidebar-contact__unread {
  display: inline-block;
  color: white;
  background-color: #0092bc;
  border-radius: 18px;
  min-width: 18px;
  height: 18px;
  padding: 0 3px;
  line-height: 18px;
  vertical-align: middle;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 500;
}

/*  End Sidebar Contact List */

@media screen and (min-width: 1000px) and (max-width: 1300px) {
  .sidebar {
    flex: 35%;
  }
  .sidebar ~ div {
    flex: 65%;
  }
}

@media screen and (min-width: 1301px) {
  .sidebar {
    flex: 30%;
  }
  .sidebar ~ div {
    flex: 70%;
  }
}
